import React from 'react';

// Path to the logo file on your project
import logo from '../assets/odt-white-logo.svg';

const Logo = () => (
  <img src={logo} alt="Odyssey Dance Theatre logo" style={{ width: 180 }} />
);

export default Logo;
